.ResultPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    margin-bottom: 5px;
  }

  &__result {
    margin-bottom: 15px;
    font-size: 22px;
  }

  &__button {
    margin-bottom: 15px;
  }
}
