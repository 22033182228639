@import "../../styles/mixins/hover";

.Button {
  pointer-events: auto;
  position: relative;
  border-radius: 6px;
  min-width: 160px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--yellow);
  color: var(--black);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 240ms, color 240ms, opacity 240ms;

  &:disabled {
    cursor: not-allowed;
    background-color: var(--gray);
    color: var(--white);
  }

  @include hoverEffect {
    background-color: var(--orange);
  }

  &__text {
    font-size: 14px;
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
