@import "../../styles/mixins/hover";

.CitiesPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-size: 16px;
    line-height: 1;
    margin-top: calc(30px - 26px);
    margin-bottom: 5px;

    text-decoration: underline;
  }

  &__cities {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 5px;

    padding-top: calc(20px - 5px);
    padding-bottom: calc(34px - 26px);
  }

  &__city {
    min-width: auto;
    min-height: auto;
    background-color: unset !important;

    @include hoverEffect {
      color: var(--orange);
    }

    &Text {
      font-size: 22px;
    }
  }
}
