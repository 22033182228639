.GameScreen {
  &__canvas {
    position: relative;
    z-index: 5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
