@import "./default/root.css";

html, body, #root {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.23;
  font-family: var(--Tinkoff-Sans-Regular);
  color: var(--black);
  background-color: var(--light-blue);
  transform-origin: left top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  width: 100%;
  height: 100%;
}

body {
  position: relative;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
}

b {
  font-weight: normal;
  font-family: var(--Tinkoff-Sans-Medium);
}

strong {
  font-weight: normal;
  font-family: var(--Tinkoff-Sans-Bold);
}

