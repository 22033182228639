@import "../../styles/mixins/hover";

.CurrentCity {
  position: fixed;
  top: 0;
  right: 6px;
  z-index: 60;

  padding: 10px;

  min-width: auto;
  min-height: auto;
  background-color: unset !important;

  &:disabled {
    color: var(--gray);
  }

  @include hoverEffect {
    color: var(--blue);
  }

  &__text {
    font-size: 14px;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: currentColor;
      position: absolute;
      top: 95%;
      left: 0;
    }
  }
}
