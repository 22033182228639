.TransitionLayout {
  &_popup_300 {
    $duration: 300ms;
    $scale_min: 0.9;

    &_Enter {
      opacity: 0;
      transform: scale($scale_min);

      &_active {
        opacity: 1;
        transform: scale(1);
        transition: opacity $duration, transform $duration;
      }
    }

    &_Exit {
      opacity: 1;
      transform: scale(1);

      &_active {
        opacity: 0;
        transform: scale($scale_min);
        transition: opacity $duration, transform $duration;
        pointer-events: none;
      }
    }
  }

  &_fade_300 {
    $duration: 300ms;

    &_Enter {
      opacity: 0;

      &_active {
        opacity: 1;
        transition: opacity $duration;
      }
    }

    &_Exit {
      opacity: 1;

      &_active {
        opacity: 0;
        transition: opacity $duration;
        pointer-events: none;
      }
    }
  }

  &_fade_500 {
    $duration: 500ms;

    &_Enter {
      opacity: 0;

      &_active {
        opacity: 1;
        transition: opacity $duration;
      }
    }

    &_Exit {
      opacity: 1;

      &_active {
        opacity: 0;
        transition: opacity $duration;
        pointer-events: none;
      }
    }
  }
}
