.GameScore {
  position: fixed;
  z-index: 10;
  bottom: 130px;
  left: 50%;
  margin-left: -15px;

  &__value {
    color: var(--blue);
    font-size: 43px;
  }
}
