@import "../../../../styles/mixins/hover";

.PopupContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  &__content {
    position: fixed;
    z-index: 105;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    will-change: transform;

    &_not_touch {
      pointer-events: none;
    }

    &_variant {
      &_popup {
        width: 218px;
        padding: 26px 25px;
      }

      &_big_popup {
        width: 250px;
        padding: 26px 25px;
      }

      &_message {
        z-index: 100;
        width: 170px;
        padding: 16px 12px;
      }
    }

    &_color {
      &_white {
        background-color: var(--white);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      }

      &_yellow {
        background-color: var(--yellow);
      }
    }

    &_custom_position {
      justify-content: flex-start;
    }
  }

  &__triangle {
    position: absolute;
    right: -5px;
    bottom: 0;
    width: 11px;
    height: 8px;
    display: flex;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__exit {
    margin-top: 15px;
  }
}

.PopupBackground {
  &__content {
    position: fixed;
    width: 100vmin;
    height: 100vmax;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__close {
    position: fixed;
    top: 6px;
    right: 6px;
    width: 48px;
    height: 48px;
    transition: opacity 240ms;

    @include hoverEffect {
      opacity: 0.8;
    }

    svg {
      width: 20px;
      height: auto;
    }
  }
}
