.Progress {
  display: flex;
  width: 250px;
  height: 6px;
  background-color: var(--black);
  border-radius: 6px;

  &__value {
    background-color: var(--yellow);
    transition: width 200ms linear;
    border-radius: 6px;
    margin: -1px;
    height: calc(100% + 2px);
  }
}
