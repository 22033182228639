.AboveScreenIndication {
  position: fixed;
  z-index: 10;
  top: 38px;
  left: 50%;
  margin-left: -15px;
  display: flex;
  color: var(--blue);

  &__value {
    padding-left: 5px;
  }

  svg {
    width: 9px;
    height: auto;
  }
}
