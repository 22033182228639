.Tribune {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  transform-origin: left bottom;
  will-change: transform;

  &__start {
    background-size: 320px 568px;
    background-repeat: no-repeat;
    background-position: left bottom;
  }

  &__midlle {
    background-size: 320px 568px;
    background-repeat: repeat-x;
    background-position: left bottom;
  }
}
