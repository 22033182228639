:root {
    --gray: #B3B3B3;
    --light-gray: #D9D9D9;
    --white: #FFFFFF;
    --black: #333333;
    --yellow: #FFDD2D;
    --orange: #F0C900;
    --blue: #7BA0CA;
    --light-blue: #E9F0F4;

    --Tinkoff-Sans-Regular: 'Tinkoff-Sans-Regular', sans-serif;
    --Tinkoff-Sans-Medium: 'Tinkoff-Sans-Medium', sans-serif;
    --Tinkoff-Sans-Bold: 'Tinkoff-Sans-Bold', sans-serif;
}
