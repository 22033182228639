$size: 16px;
$loader-colors: var(--black);
$loader-dash: 50;
$loader-duration: calc(length($loader-colors) * 1.5s);
$loader-duration-alt: calc($loader-duration / length($loader-colors));
$loader-keyframe: calc(1 / (length($loader-colors) * 2) * 100);

.Spinner {
  width: $size;
  height: $size;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity var(--transitionDuration);

  svg {
    width: 100%;
    height: 100%;
    animation: loader-turn $loader-duration-alt linear infinite;

    @keyframes loader-turn {
      50% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(720deg);
      }
    }
  }

  circle {
    animation: loader-stroke $loader-duration linear infinite;
    fill: none;
    stroke-dasharray: $loader-dash;
    stroke-dashoffset: $loader-dash;
    stroke-linecap: round;
    stroke-width: 3;
    transition: stroke var(--transitionDuration);

    @for $i from 1 through length($loader-colors) {
      &:nth-child(#{$i}) {
        stroke: nth($loader-colors, $i);

        @if $i > 1 {
          animation-delay: ($i - 1) * $loader-duration-alt;
        }
      }
    }

    @keyframes loader-stroke {
      #{$loader-keyframe * 1%} {
        stroke-dashoffset: 0;
      }
      #{$loader-keyframe * 2%},
      100% {
        stroke-dashoffset: $loader-dash;
      }
    }
  }
}
