.Attempts {
  pointer-events: none;
  position: fixed;
  z-index: 10;
  bottom: 30px;
  left: 50%;
  margin-left: 10px;
  border-radius: 10px;
  padding: 4.5px;
  background-color: var(--white);
  border: 3px solid var(--yellow);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__item {
    position: relative;
    width: 30px;
    margin: 2.5px;
    filter: var(--light-gray);

    &Active {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      opacity: 0;
      transition: opacity 300ms;

      &_shown {
        opacity: 1;
      }
    }

    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
