.Preload {
  &__content {
    position: fixed;
    z-index: 45;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background-color: var(--light-blue);
  }

  &__label {
    margin-bottom: 10px;
  }
}

