.FinishResultPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 175px;

  &__hr {
    width: 132px;
    height: 1px;
    background-color: var(--light-blue);
    margin-bottom: 15px;
  }

  &__title {
    margin-bottom: 5px;
  }

  &__result {
    margin-bottom: 15px;
    font-size: 22px;
  }

  &__button {
    margin-bottom: 15px;
  }
}
